.weeklyPlan {
  margin: auto;
  border:  none; /* dimgray 1px dashed; */
  width: 1232px;
  height: 860px;
  padding: 0;
}

.day, .timeslotRowsHeader {
  border: none; /* darkred 1px solid; */
  width: 150px;
  height: 100%;
  padding: 0;
  float: left;
}

.timeslotRowsHeader {
  text-align: right;
}


.dayHeader {
  text-align: center;
  width: 100%;
  height: 100%;
}

.timeslot, .timeslotRowHeader {
  border: none;  /* darkblue 1px solid; */
  width: 150px;
  height: 30px;
  padding: 0;
  vertical-align: center; /* TODO - how to vertically align? */
  overflow: hidden;
}

.rowOdd {
  background-color: white;
}

.rowEven {
  background-color: #dfdfdf;
}

.tag {
  margin: 1rem;
  padding: 1.5rem;
  text-align: left;
  color: inherit;
  text-decoration: none;
  border: 1px solid #eaeaea;
  border-radius: 10px;
  transition: color 0.15s ease, border-color 0.15s ease;
  max-width: 300px;
  list-style-type: none;
  float: left;
  background-color: coral;

}

.tagColor1 {
  background-color: #beb9db;
}

.tagColor2 {
  background-color: #bd7ebe;
}

.tagColor3 {
  background-color: #7eb0d5;
}

.tagColor4 {
  background-color: #8bd3c7;
}

.tagColor5 {
  background-color: #b2e061;
}

.tagColor6 {
  background-color: #ffee65;
}

.tagColor7 {
  background-color: #ffb55a;
}

.tagColor8 {
  background-color: #fdcce5;
}

.tagColor9 {
  background-color: #fd7f6f;
}


.tagColorNone {
  background-color: transparent;
}

.tag:hover,
.tag:focus,
.tag:active {
  color: #0070f3;
  border-color: #0070f3;
}

.tag span {
  margin: 0;
  font-size: 1.25rem;
  line-height: 1.5;
}

.tagCollection {
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  max-width: 800px;
  border: lightslategray 1px dashed;
;

}








.App {
}

@media (prefers-reduced-motion: no-preference) {
}

.App-header {
  background-color: #282c34;
  min-height: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
